<template>
  <page-view title="绑定户号" left-arrow white-bg>
    <div class="driver-box"></div>
    <van-form @submit="onSubmit" ref="form">
      <van-field
        v-model="form.yhbh"
        name="yhbh"
        label="户号："
        placeholder="请输入户号"
        :rules="[{ required: true, message: '请输入户号' }]"
      />
      <van-field
        v-model="form.yhsj"
        name="yhsj"
        label="手机号"
        type="tel"
        :maxlength="11"
        :formatter="formatterNumber"
        placeholder="请输入手机号"
        :rules="[
          { required: true, message: '请输入手机号' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}
        ]"
      />
      <van-field v-model="form.sjyzm" name="sjyzm" center clearable label="验证码" placeholder="请输入短信验证码">
        <template #button>
          <sms-code-button v-model="form.yhsj" @click="getSMSCode"/>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit">提交</van-button>
      </div>

      <div class="container">
        <div class="card">
          <van-cell :style="{backgroundColor: 'transparent'}">
            <template #title>
              <h2 class="inline-title">如何查询户号？</h2>
            </template>
            <template #label>
              <ol>
                <li>1、如果您办理了短信通知业务，可以在短信内容里找到;</li>
                <li>2、之前的电费账单里面可以找到；</li>
                <li>3、请到当地营业厅或者拨打营业厅电话查询。</li>
              </ol>
              <div :style="{textAlign: 'center'}" class="mt-10">
                <img src="@/assets/images/my/bind-yhbh.jpg" :style="{width: '160px'}" />
              </div>
            </template>
          </van-cell>
        </div>
      </div>
    </van-form>
  </page-view>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from "vuex";
import { 
  wXGZHService_sendWxgzhDxyzm, 
  wXGZHService_addYhsjhAndYxhh 
} from "@/api/grzx";
import SmsCodeButton from '@/components/SmsCodeButton';
export default {
  name: 'BindYhbh',
  components: {SmsCodeButton},
  data() {
    return {
      form: {
        yhbh: this.yhsj || '',
        yhsj: '',
        sjyzm: ''
      }
    };
  },
  computed: {
    ...mapGetters(["openId",'yhsj']),
  },
  methods: {
    formatterNumber(value) {
      // 过滤输入的非数字
      return value.replace(/\D/g, "");
    },
    resetForm() {
      this.form = {
        yhbh: this.yhsj || '',
        yhsj: '',
        sjyzm: ''
      }
    },
    onSubmit(values) {
      const {yhsj,sjyzm,yhbh} = this.form;
      wXGZHService_addYhsjhAndYxhh(this.openId, yhsj,sjyzm,yhbh).then(res => {
        if (res.content.status == 200) {
          this.$toast.success('绑定成功');
          setTimeout(() => {
            this.$router.back();
          }, 1000);
        } else {
          this.$toast(res.content.message)
        }
      }).catch(err => {
        this.$toast(err.message)
      })
      console.log('submit', values);
    },
    getSMSCode: _.debounce(function() {
      wXGZHService_sendWxgzhDxyzm(this.form.yhsj, this.openId,).then(res => {
        this.$notify({type: res.content.status == 200 ? 'success' : 'danger', message: res.content.message});
      }).catch(err => {
        this.$notify({ type: 'danger', message: err.message});
      });
    }, 500, {leading: true}),
  },
  watch: {
    yhsj: {
      handler(val) {
        if (val) {
          this.form.yhsj = val;
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import 'src/styles/variable.scss';
  .inline-title {
    color: $color-primary;
  }
  .inline-title::before {
    background-color: $color-primary;
  }
</style>